<template>
    <div>
        <Header title="Historiques des votes" text="Retrouvez tous les votes depuis la session de mai 2021" />
        <b-container>
            <p class="lead text-left">
                Certains votes, sélectionnés par les journalistes, sont explicités (titre, signification du oui, signification du non). Les autres sont référencés sous leur dénomination officielle fournie par le bureau du Grand Conseil.
            </p>
            <SearchBar @search="filterVotes" class="mt-4" />
            <b-table id="my-table" 
                class="mt-5 text-left" 
                :items="filteredVotes"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                @row-clicked="goToPage"                    
                striped
                hover
            >
                <template #cell(result)="data">
                    <span v-html="data.value"></span>
                </template>
                <template #cell(vote)="data">
                    {{ data.item.affair ? data.item.affair : data.item.label }}
                </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                class="nouvelliste-pagination"
            />
        </b-container>
    </div>
</template>

<script>
import Header from './utils/Header'
import SearchBar from './utils/SearchBar'   
import * as d3 from 'd3'
import moment from 'moment'
export default {
    components: {
        Header,
        SearchBar,
    },
    computed: {
        rows() {
            return this.filteredVotes.length
        },
        fields() {
            return [
                {
                    key: 'section',
                    label: 'Thématique',
                    sortable: true
                },
                {
                    key: 'vote',
                    label: 'Vote',
                    sortable: false,
                },
                {
                    key: 'result',
                    label: 'Résultat',
                    sortable: true,
                    formatter: (value) => {
                        return value > 50 ? "<span class='text-success'><b>OUI</b> (" + value.toFixed(2) + "%)</span>" : "<span class='text-danger'><b>NON</b> (" + (100 - value).toFixed(2) + "%)</span>"
                    }
                },
                {
                    key: 'startTime',
                    label: 'Date et heure',
                    sortable: true,
                    formatter: (value) => {
                        return moment(value).format('DD.MM.YYYY HH:mm')
                    },
                },
            ]
        },
    },
    data() {
        return {
            votes: [],
            filteredVotes: [],
            perPage: 8,
            currentPage: 1,
        }
    },
    created() {
        d3.csv('/votes/AllVotes.csv').then((data) => {
            data.map((v) => {
                v.result = parseInt(v.numYes) * 100 / (parseInt(v.numYes) + parseInt(v.numNo) + parseInt(v.numAbst))
            })
            this.votes = data.sort((a, b) => {
                return new Date(b.startTime) - new Date(a.startTime)
            })
            this.filteredVotes = this.votes
        })
    },
    methods: {
        filterVotes(query) {
            if(!query || query === '') {
                this.filteredVotes = this.votes
                return
            }
            this.filteredVotes = this.votes.filter(v => v.label.toLowerCase().includes(query.toLowerCase()) || v.affair.toLowerCase().includes(query.toLowerCase()) || v.note.toLowerCase().includes(query.toLowerCase()) || v.startTime.includes(query.toLowerCase()))
            this.currentPage = 1
        },
        goToPage(item) {
            this.$router.push({ name: 'vote', params: { id: item.affairVoteId } })
        }
    },
    metaInfo: {
        title: 'Historique des votes'
    }
}
</script>

<style>
#my-table {
    cursor: pointer;
}
</style>