<template>
  <b-container id="error" class="mx-auto mt-5 pt-5">
    <div class="my-auto">
      <h1 class="text-nouvelliste">La page que vous cherchez n'existe pas ou plus.</h1>
      <router-link to="/">Retour à l'accueil</router-link>
    </div>
  </b-container>
</template>

<script>
export default {
  metaInfo: {
    title: 'Page introuvable'
  }
}
</script>

<style>
#error {
  margin-top: 25vh !important;
}
</style>