<template>
  <div>
    <Header title="Comment vote le Grand Conseil ?" text="Les votes du Parlement cantonal sous la loupe" />
    <b-container class="text-left">
      <div class="lead">
        <p>
          Le <a href="https://parlement.vs.ch/app/fr/home" target="_blank">Grand Conseil valaisan</a> se réunit plusieurs fois par année pour prendre des décisions 
          sur l'avenir du canton. Mais quels sont les sujets abordés ? Quels thèmes divisent les partis, les générations ou les sexes ? 
          En résumé, <b class="font-weight-bold">comment vote le Grand Conseil</b> ? C'est, en substance, la mission de ce site élaboré en partenariat avec la HES-SO Valais/Wallis.
        </p>
        <p>
           Certains votes, sélectionnés par les journalistes, sont explicités (titre, signification du oui, signification du non). Les autres sont référencés sous leur dénomination officielle fournie par le bureau du Grand Conseil.
           L'ensemble des votes, depuis mai 2021, est disponible sous <b-link to="/tous-les-votes">Historique des votes</b-link>.
        </p>
      </div>
      <VoteSelection />
    </b-container>
  </div>
</template>

<script>
import Header from './utils/Header'
import VoteSelection from './votes/VoteSelection'
export default {
  components: {
    Header,
    VoteSelection,
  },
  metaInfo: {
    title: 'Accueil'
  }
}
</script>

<style>

</style>