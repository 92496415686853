<template>
  <div>
    <Header title="À propos" />
    <b-container>
        <div class="section">
          <h3 class="text-nouvelliste">Mieux comprendre le Grand Conseil</h3>
          <p>
            Les Valaisannes et les Valaisans ont élu 260 personnes, 130 député-e-s et 130 suppléant-e-s, pour les représenter au Parlement cantonal. En partenariat avec la HES-SO Valais-Wallis, «Le Nouvelliste» a développé cette plateforme pour répertorier et analyser leurs votes.
          </p>
          <p>
            Différences hommes-femmes, différences selon l’âge, selon les partis et en leur sein, ce site doit permettre de mieux visualiser les décisions du plénum. Ces dernières, publiques, sont parfois difficiles d’accès. Cette plateforme a pour objectif d’ouvrir la politique cantonale au plus grand nombre.
          </p>
          <h3 class="text-nouvelliste">Des données objectives</h3>
          <p>
            Les données brutes des votes sont celles mises en ligne par le service parlementaire du Grand Conseil à la fin de chaque session. Elles sont publiées quelques jours plus tard sur le site 
            <a href="https://grandconseil-lenouvelliste.ch">grandconseil-lenouvelliste.ch</a>. Les votes des député-e-s et des suppléant-e-s qui ont siégé sont alors accessibles.
          </p>
          <p>
            Certains objets, intéressants pour le public, sont explicités par les journalistes de la rédaction (signification du oui, signification du non).            
          </p>
          <h3 class="text-nouvelliste">Partenariat HES-SO Valais/Wallis - Nouvelliste</h3>
          <p>
            Ce site est le fruit d’une réflexion menée avec la HES-SO Valais-Wallis, partenaire du projet. C’est elle qui gère les aspects techniques (hébergement, transfert des données, etc) alors que les journalistes du Nouvelliste, eux, analysent les votes et sont en charge des aspects rédactionnels.
          </p>
        </div>
    </b-container>
  </div>
</template>

<script>
import Header from './utils/Header'
export default {
  components: {
    Header,
  },    
  metaInfo: {
      title: 'À propos'
  }
}
</script>

<style>

</style>