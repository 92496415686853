import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VueMatomo from 'vue-matomo'
import router from "./router";
/* FontAwesome Config */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'
/* Bootstrap */
import { BootstrapVue } from 'bootstrap-vue'
/* Apex Charts */
import VueApexCharts from 'vue-apexcharts'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.use(BootstrapVue)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.use(VueMatomo, {
  host: "https://grandconseil-lenouvelliste.ch/matomo/",
  
  siteId: 1,

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 5,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the tracker endpoint entirely
  // Default: undefined
  trackerUrl: undefined,

  // Overrides the tracker script path entirely
  // Default: undefined
  trackerScriptUrl: undefined,

  // Whether or not to log debug information
  // Default: false
  debug: true,

  // User ID
  // Default: undefined
  userId: undefined,

  preInitActions: [
    ['setCustomVariable', '1', 'VisitorType', 'Member'],
    ['appendToTrackingUrl', 'new_visit=1']
  ]
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
