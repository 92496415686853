<template>
    <div class="border-top border-secondary pt-3">
        <h2 class="text-nouvelliste">Sélection de votes</h2>
        <div v-if="groupedVotes">
            <b-card v-for="votes in groupedVotes" 
                :key="votes.section" 
                class="w-75 my-4 border rounded"            
                no-body   
            >
                <b-card-header class="bg-dark text-light">
                    <h3>{{ votes.section ? votes.section : 'Divers' }}</h3>
                </b-card-header>
                <router-link v-for="vote in votes.votes" 
                    :key="vote.timeStart" 
                    :to="'/vote/' + vote.affairVoteId"
                    class="hovering"
                >
                    <b-card-body>
                        <div class="lead text-dark font-weight-bold">
                            <font-awesome-icon icon="vote-yea" class="mr-3" />
                            {{ vote.affair }}
                            <div class="small">{{ formattedDate(vote.startTime) }}</div>
                        </div>
                    </b-card-body>
                </router-link>
            </b-card>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3'
import moment from 'moment'
export default {
    data() {
        return {
            votes: null,
            sections: null,
            groupedVotes: null,
        }
    },
    created() {
        d3.csv('/votes/AllVotes.csv').then((data) => {
            this.votes = data.filter(d => d.affair)
            this.sections = [...new Set(this.votes.map((v) => v.section))]
            this.groupedVotes = []
            this.sections.map((s) => {
                if(s || s !== '')
                    this.groupedVotes.push({
                        section: s,
                        date: moment(this.votes.find((v) => v.section === s).startTime).format('MMMM YYYY'),
                        votes: this.votes.filter((v) => v.section === s).sort((a, b) => moment(b.startTime) - moment(a.startTime))
                    })
            })
            this.groupedVotes.sort((a, b) => moment(b.date) - moment(a.date))
        })
    },
    methods: {
        formattedDate(date) {
            return moment(date).format('[le] DD.MM.YYYY à HH:mm')
        },
    },
}
</script>

<style lang="scss">
.hovering:hover {
    text-decoration: none;
    background-color: theme-color('light');
}

.hovering:hover * {
    color: theme-color('nouvelliste') !important;
}
</style>