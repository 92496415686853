<template>
    <b-navbar type="light" id="header" toggleable="md">
        <b-navbar-brand to="/">
            <span class="font-weight-bold">Comment vote le Grand Conseil ?</span>
        </b-navbar-brand>   
        <b-navbar-toggle target="nav-collapse" />
        <b-collapse id="nav-collapse" class="text-left" is-nav>
            <b-navbar-nav class="font-weight-bold">
                <b-nav-item to="/tous-les-votes">
                    <span class="text-secondary">Historique des votes</span>
                </b-nav-item>
                <b-nav-item to="/a-propos">
                    <span class="text-secondary">À propos</span>                    
                </b-nav-item>
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item href="https://www.lenouvelliste.ch/">
                    <img src="../assets/images/logo-nouvelliste.gif" height="25" class="d-inline-block align-top" alt="Accueil">
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>


<script>
export default {

}
</script>

<style>
#header {
    box-shadow: 0px 1px 10px rgba(255, 0, 0, 0.3);
}
</style>