<template>
  <div v-if="this.currentVote">
      <Header :title="currentVote.affair ? currentVote.affair : currentVote.label" margin-bottom="3" />
      <b-container class="text-left">
        <VoteHeader :next="nextVote" :previous="previousVote" />
        <div class="mt-4">
          <b-alert v-if="currentVote.warning" variant="warning" show>
            <h5>
              <font-awesome-icon icon="exclamation-triangle" class="mr-2" />
              Remarque
            </h5>
            <p v-html="currentVote.warning" />
          </b-alert>
          <p class="lead" v-html="currentVote.note" />
          <b-card no-body>
             <b-card-header class="bg-dark text-light">
               <b-row>
                 <b-col sm="9">
                  <h6>Référence officielle du vote : "<b>{{ currentVote.label }}</b>"</h6>
                  <h6 v-if="currentVote.attachment">Détail des articles et amendements votés :
                      <a :href="'/attachments/' + currentVote.attachment" download>
                        <b>télécharger le PDF</b>
                        <font-awesome-icon class="ml-1" icon="file-alt" />
                      </a>                           
                  </h6>
                  <span class="font-italic small">Voté le {{ voteDate }}</span>
                 </b-col>
                 <b-col class="text-right mx-3">
                   <b-button variant="nouvelliste" size="sm" @click="showPopup = !showPopup">
                     Comment interpréter ce vote
                    <font-awesome-icon icon="question-circle" class="ml-1" />
                   </b-button>
                 </b-col>
               </b-row>
            </b-card-header>
            <b-collapse title="Comment interpréter ce vote ?" 
              v-model="showPopup" 
            >
            <b-card-body class="bg-light">
              <p>
                En votant, les membres du Grand Conseil expriment leur choix entre deux variantes d'un article d'une future loi. En votant <b class="text-yes">OUI</b>, on choisit l'une des deux variantes. En votant <b class="text-no">NON</b>, on choisit l'autre. On peut aussi s'abstenir en votant <b>ABST</b>.
              </p>
              <p>
                La signification du <b class="text-yes">OUI</b> et du <b class="text-no">NON</b> (en d'autres termes, la variante d'article qui correspond au <b class="text-yes">OUI</b> et au <b class="text-no">NON</b>) est indiquée au-dessous du résultat lorsque c'est possible. Vous la trouverez aussi dans le détail des articles et amendements votés. La référence officielle du vote mentionnée au-dessus du résultat permet d'y retrouver l'article en question.
              </p>
              <b-button variant="nouvelliste" size="sm" @click="showPopup = false">                
                <font-awesome-icon icon="check-circle" class="mr-1" />
                J'ai compris !
              </b-button>
            </b-card-body>
            </b-collapse>
            <b-card-body>
              <apexchart v-if="charts.yesNo.options && charts.yesNo.series" width="100%" height="200" type="bar" :options="charts.yesNo.options" :series="charts.yesNo.series"></apexchart>      
              <b-row class="px-4 vote-distribution-labels">
                <b-col class="text-left" :style="{color: colors[0]}">
                  <b>OUI</b>
                  <p>
                    <b>{{ currentVote.numYes }}</b> ({{ (parseInt(currentVote.numYes) * 100 / (parseInt(currentVote.numYes) + parseInt(currentVote.numAbst) + parseInt(currentVote.numNo))).toFixed(1) }}%)
                  </p>
                </b-col>
                <b-col class="text-center">
                  <b>ABST</b>
                  <p>
                    <b>{{ currentVote.numAbst }}</b> ({{ (parseInt(currentVote.numAbst) * 100 / (parseInt(currentVote.numAbst) + parseInt(currentVote.numYes) + parseInt(currentVote.numNo))).toFixed(1) }}%)
                  </p>
                </b-col>
                <b-col class="text-right" :style="{color: colors[2]}">
                  <b>NON</b>
                  <p>
                    <b>{{ currentVote.numNo }}</b> ({{ (parseInt(currentVote.numNo) * 100 / (parseInt(currentVote.numYes) + parseInt(currentVote.numAbst) + parseInt(currentVote.numNo))).toFixed(1) }}%)
                  </p>
                </b-col>
              </b-row>        
              <b-row v-if="currentVote.meaningYesText && currentVote.meaningNoText" class="text-left mt-4 px-4">
                <b-col lg="6" md="12">
                  <h5 class="text-yes">Signification du <b>OUI</b></h5>
                  <p v-html="currentVote.meaningYesText" />
                </b-col>
                <b-col lg="6" md="12">
                  <h5 class="text-no">Signification du <b>NON</b></h5>
                  <p v-html="currentVote.meaningNoText" />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body class="mt-3">
            <b-card-header class="bg-dark text-light">
              <h4>Votes par catégories socio-démographiques</h4>
            </b-card-header>
            <b-card-body>
              <b-row no-gutters>
                <b-col v-for="chart in charts.categories" :key="chart.title" md="12" lg="6">
                  <apexchart v-if="chart.options && chart.series" width="100%" height="350" type="bar" :options="chart.options" :series="chart.series"></apexchart>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body class="mt-3">
            <b-card-header class="bg-yes text-white">
              <h4>{{ allVotes.filter((v) => v.vote == 'Oui/Ja').length }} ont voté OUI</h4>
            </b-card-header>
            <b-card-body>
              <b-table v-if="allVotes"
                :items="allVotes.filter((v) => v.vote == 'Oui/Ja')"
                :fields="yesNoFields"
                small
              >
              </b-table>
            </b-card-body>
          </b-card>
          <b-card no-body class="mt-3">
            <b-card-header class="bg-no text-white">
              <h4>{{ allVotes.filter((v) => v.vote == 'Non/Nein').length }} ont voté NON</h4>
            </b-card-header>
            <b-card-body>
              <b-table v-if="allVotes"
                :items="allVotes.filter((v) => v.vote == 'Non/Nein')"
                :fields="yesNoFields"
                small
              >
              </b-table>
            </b-card-body>
          </b-card>
          <b-card no-body class="mt-3">
            <b-card-header class="bg-abst text-white">
              <h4>{{ allVotes.filter((v) => v.vote == 'Abst./Enth.').length }} se sont abstenu-e-s</h4>
            </b-card-header>
            <b-card-body>
              <b-table v-if="allVotes"
                :items="allVotes.filter((v) => v.vote == 'Abst./Enth.')"
                :fields="yesNoFields"
                small
              >
              </b-table>
            </b-card-body>
          </b-card>
        </div>
        <VoteMetaData v-if="currentVote" :vote="currentVote" />
      </b-container>
  </div>
</template>

<script>
import * as d3 from 'd3'
import moment from 'moment'
import Header from './utils/Header'
import VoteHeader from './utils/VoteHeader'
import VoteMetaData from './utils/VoteMetaData'
export default {
  components: {
    Header,
    VoteHeader,
    VoteMetaData,
  },
  data() {
    return {
      nextVote: null,
      previousVote: null,
      currentVote: null,
      allVotes: null,
      voteDate: null,
      showPopup: false,
      charts: {
        yesNo: {
          series: null,
          options: null,
        },
        categories: []
      },
      colors: ['rgb(0, 130, 129)', '#353535', 'rgb(152, 0, 0)'],
      categories: ['Parti', 'Groupe', 'Genre', 'Âge', 'Région', 'District', 'Fonction'],
      groupsOrder: ['Les Vert.e.s', 'PS/GC', 'PDCVr', 'CSPO', 'CVPO', 'PLR/FDP', 'UDC', 'SVPO'],
      partisOrder: ['LES VERT.E.S', 'PS', 'PDC', 'PLR', 'UDC'],
      districtsOrder: ['Östlich Raron', 'Brig', 'Visp', 'Westlich Raron', 'Leuk', 'Sierre', 'Sion', 'Hérens', 'Conthey', 'Martigny', 'Entremont', 'St-Maurice', 'Monthey'],
      fonctionOrder: ['Député-e', 'Député-e suppléant-e'],
      regionsOrder: ['Haut-Valais', 'Valais Romand'],
      yesNoFields: [
        {
          key: 'name', 
          label: 'Nom',
          sortable: true,
        }, 
        {
          key: 'Parti',   
          sortable: true,
        },
        {
          key: 'group',
          label: 'Groupe',
          sortable: true,
        },
        {
          key: 'Fonction',
          label: 'Fonction',
          sortable: true  
        },
        {
          key: 'Région',
          label: 'Région',
          sortable: true,
        },
        {
          key: 'vote',
          formatter: (val) => {
            switch(val) {
              case 'Oui/Ja':
                return 'Oui'
              case 'Non/Nein':
                return 'Non'
              default:
                return 'Abst.'
            }
          },
        }
      ],
      ageClasses: {
        min: [18, 31, 46, 65],
        max: [30, 45, 65, 100],
      },
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchVotes(to.params.id)
    next()
  },
  created() {
    this.fetchVotes(this.$route.params.id)
  },
  methods: {
    fetchVotes(id) {
      d3.csv('/votes/AllVotes.csv').then((data) => {
        this.currentVote = data.find((d) => d.affairVoteId == id)
        this.voteDate = moment(this.currentVote.startTime).format('DD.MM.YYYY à HH:mm')
        const index = data.findIndex((d) => d.affairVoteId == id)
        this.previousVote = this.getPreviousVote(index, 0, data)
        this.nextVote = this.getPreviousVote(index, data.length, data)
        d3.csv('/votes/' + this.currentVote.affairVoteId + '.csv').then((individualVotes) => {
          this.allVotes = this.resolvePCS(individualVotes)
          this.generateCharts()
        })
      })
    },
    generateCharts() {
      this.generateYesNo()
      this.charts.categories = []
      this.categories.map((c) => {
        this.generateCategory(c)
      })
    },
    generateYesNo() {
      this.charts.yesNo.series = [
        {
          name: 'OUI',
          data: [this.currentVote.numYes]
        },
        {
          name: 'ABST',
          data: [this.currentVote.numAbst]
        },
        {
          name: 'NON',
          data: [this.currentVote.numNo]
        }
      ]
      this.charts.yesNo.options = {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        annotations: {
          xaxis: [
            {
              x: (parseInt(this.currentVote.numYes) + parseInt(this.currentVote.numNo) + parseInt(this.currentVote.numAbst)) / 2,
              borderColor: '#cccccc',
            }
          ]
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "25px",
          }
        },
        xaxis: {
          categories: [''],
          max: this.currentVote.numYes + this.currentVote.numNo + this.currentVote.numAbst,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        grid: {
          show: false,
          borderColor: '#fff',          
        },
        yaxis: {
          title: {
            show: false 
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 1
        },
        colors: this.colors,
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: (val) => {
              return `${val} (${(val * 100 / (parseInt(this.currentVote.numYes) + parseInt(this.currentVote.numNo) + parseInt(this.currentVote.numAbst))).toFixed(2)}%)`
            },
          },
        },
        legend: {
          show: false
        },
      }
    },
    generateCategory(category) {
      let allOptions
      if (category !== 'Âge') {
        allOptions = this.fixOrder(category, [...new Set(this.allVotes.map((v) => v[category]))])
      } else {
        allOptions = []
        for(let i = 0; i < this.ageClasses.min.length; i++) {
          const label = this.ageClasses.min[i] + (this.ageClasses.max[i] !== 100 ? ('-' + this.ageClasses.max[i]) : '+')
          allOptions.push(label)
        }
      }
      const series = [
        {
          name: 'OUI',
          data: this.getData(allOptions, category, 'Oui/Ja')
        },
        {
          name: 'ABST',
          data: this.getData(allOptions, category, 'Abst./Enth.')
        },
        {
          name: 'NON',
          data: this.getData(allOptions, category, 'Non/Nein')
        },
      ]
      const options = {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        title: {
          text: category
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "17px",
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: allOptions,
        },
        legend: {
          show: false
        },
        menu: {
          show: false
        },
        fill: {
          opacity: 1
        },
        colors: this.colors,
      }
      this.charts.categories.push({
        title: category,
        series,
        options
      })
    },
    getData(options, category, vote) {
      const data = []
      if(category == 'Âge') {
        for(let i = 0; i < this.ageClasses.min.length; i++) {
          data.push(this.allVotes.filter((v) => v.vote == vote && this.getAge(v[category]) >= this.ageClasses.min[i] && this.getAge(v[category]) <= this.ageClasses.max[i]).length)
        }
      } else {
        options.map((o) => {
          data.push(this.allVotes.filter((v) => v[category] == o && v.vote == vote).length)
        })
      }
      return data
    },
    getAge(birthdate) {
      return moment(this.currentVote.startTime, 'YYYY-MM-DD HH:mm:ss').year() - moment(birthdate, 'DD.MM.YYYY').year()
    },
    fixOrder(category, defaultOptions) {
      switch (category) {
        case 'Genre':
          return ['H', 'F']
        case 'Groupe':
          return this.groupsOrder
        case 'Parti':
          return this.partisOrder
        case 'District':
          return this.districtsOrder
        case 'Région':
          return this.regionsOrder
        case 'Fonction':
          return this.fonctionOrder
        default:
          return defaultOptions
      }
    },
    getPreviousVote(index, limit, data) {
      let affair = null
      let i = index
      if(limit > 0) {
        while(i < limit && affair == null) {
          i++
          try {
            affair = data[i].affairVoteId
          } catch(e) {
            return affair
          }
        }
      } else {
        while(i >= limit && affair == null) {
          i--
          try {
            affair = data[i].affairVoteId
          } catch(e) {
            return affair
          }
        }
      }
      return affair
    },
    resolvePCS(data) {
      return data.map((x) => { 
        if(x.Parti === 'PCS')
          x.Parti = 'PS'
        return x
      })
    },
  },
  metaInfo() {
    return {
      title: this.currentVote ? (this.currentVote.affair ? this.currentVote.affair : this.currentVote.label) : 'Vote'
    }
  },
}
</script>

<style>
.vote-distribution-labels {
  margin-top: -40px;
  font-size: 1.6em;
}
.text-yes {
  color:rgb(0, 130, 129);
}
.text-no {
  color:rgb(152, 0, 0);
}
.bg-yes {
  background-color:rgb(0, 130, 129) !important;
}
.bg-no {
  background-color:rgb(152, 0, 0) !important;
}
.bg-abst {
  background-color: #353535 !important;
}
</style>