<template>
  <b-row id="footer" class="border-top border-secondary bg-white text-center py-2 text-muted small" no-gutters>
      <b-col sm="4" class="px-5 text-left my-auto">
          <span>© {{ today }} Développé par la HES-SO Valais/Wallis</span>
      </b-col>
      <b-col sm="4" class="px-5 text-center my-auto">
          Ce site utilise le code de la librairie <a href="https://github.com/evequozf/qui-vote-quoi" target="_blank">qui-vote-quoi</a>, sous licence MIT.
      </b-col>
      <b-col class="px-5 text-right my-auto">
          <a href="https://www.hevs.ch/fr/">
            <img src="../assets/images/logo-hes-so.png" target="_blank" height="35">
          </a>
      </b-col>
  </b-row>
</template>

<script>
export default {
    computed: {
        today() {
            return new Date().getFullYear()
        }
    }
}
</script>

<style>
#footer {
    z-index: 10000 !important;
}
</style>