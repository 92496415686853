<template>
  <div class="mt-4 small">
      <div>Référence officielle du vote : "<strong>{{ vote.label }}</strong>"</div>
      <div v-if="vote.attachment">Détail des articles et amendements votés : <a :href="vote.attachment" download>{{ vote.attachment }}</a></div>
      <div>Séance : <strong>{{ vote.section }}</strong></div>
      <div v-if="vote.linkVideo">Vidéo des débats : <a :href="vote.linkVideo">{{ vote.linkVideo }}</a></div>
      <div>Date et heure : <strong>le {{ formattedDate }}</strong></div>
      <div> 
        <a :href="'/votes/' + vote.affairVoteId + '.csv'" download>
            <b>Télécharger les données du vote</b>
        </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        vote: { type: Object, required: true }
    },
    data() {
        return {
            formattedDate: ''
        }
    },
    mounted() {
        this.formattedDate = moment(this.vote.startTime, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY à HH:mm:ss')
    }
}
</script>

<style>

</style>